import React from 'react';

export default class Preloader extends React.Component {
    render() {
        // /* Preloader  */
        return (
            <div id="preloader">
                <div id="load" />
            </div>
        );
    }
}